/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  DatePicker,
  Descriptions,
  Drawer,
  Dropdown,
  Form,
  Input,
  Menu,
  message,
  Modal,
  notification,
  Popconfirm,
  Radio,
  Select,
  Table,
  Tag,
  Tooltip,
} from "antd";
import {
  CarOutlined,
  DownloadOutlined,
  DownOutlined,
  EditOutlined,
  EyeOutlined,
  FileExcelOutlined,
  PrinterOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import EllipsisDropdown from "components/shared-components/EllipsisDropdown";
import Flex from "components/shared-components/Flex";
import moment from "moment/min/moment-with-locales";
import { DATE_FORMAT_DD_MM_YYYY, DATE_FORMAT_DD_MM_YYYY_HH_mm, DATE_FORMAT_YYYY_MM_DD } from "constants/DateConstant";
import utils from "utils";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { uniqueMergeArrays } from "../../../functions/arrays";
import { isLoaded, useFirestore } from "react-redux-firebase";
import { CURRENCY, DELIVERY_DATE_WARNING_DAYS, DELIVERY_OPTIONS } from "constants/DeliveryConstants";
import Text from "antd/lib/typography/Text";
import { FIRESTORE_ORDERS_TABLE } from "../../../constants/FirestoreConstant";
import {
  ORDER_STATUS_COMPLETED,
  ORDER_STATUS_CREATED,
  ORDER_STATUS_PAID,
  ORDER_STATUS_RETURNED,
  ORDER_STATUS_SHIPPED,
} from "../../../constants/OrderConstant";
import { useReactToPrint } from "react-to-print";
import { generateInvoice, getPickListData, getSendListData } from "../../../functions/orders";
import PickListTable from "../../../components/shared-components/Table/PickListTable";
import { formatInvoiceFileName, formatOrderDate, formatPrice, getPaymentStatus } from "../../../functions/formatters";
import _ from "lodash";
import locale from "antd/es/date-picker/locale/nl_NL";
import { calculateVat } from "functions/calculations";
import { createDeliveryOrder } from "functions/api/optimoroute";
import { setOrdersMaxDaysAgo } from "../../../redux/actions/Queries";
import styled from "styled-components";
import { downloadFile, isMobile } from "../../../functions/browser";
import axios from "axios";
import { getFirebaseIDToken } from "../../../functions/firebase/firebase";
import OrderListTable from "../../../components/shared-components/Table/OrderListTable";
import PackingSlip from "../../../components/shared-components/Table/PackingSlip";
import SendLabel from "../../../components/shared-components/Table/SendLabel";
import * as dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";

dayjs.extend(isBetween);

const { RangePicker } = DatePicker;
// const ExcelFile = ReactExport.ExcelFile;
// const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const Orders = () => {
  const firestore = useFirestore();
  const dispatch = useDispatch();
  const promiseResolveRef = useRef(null);

  const orderListRef = useRef();
  const pickListRef = useRef();
  const packingSlipRef = useRef();
  const sendListRef = useRef();
  const orders = useSelector((state) => state.firestoreReducer.ordered.orders);
  const products = useSelector((state) => state.firestoreReducer.ordered.products);
  const orderMaxDaysAgoQuery = useSelector((state) => state.queries.ordersMaxDaysAgo);
  const categories = useSelector((state) => state.firestoreReducer.ordered.categories);
  const [editOrderForm] = Form.useForm();
  const [filteredList, setFilteredList] = useState(orders);
  const [paymentStatusList, setPaymentStatusList] = useState([]);
  const [orderStatusList, setOrderStatusList] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [visible, setVisible] = useState(false);
  const [isPrinting, setIsPrinting] = useState(false);
  const [order, setOrder] = useState(null);
  const { t } = useTranslation();
  const hasWeightProperty = order ? order.products.some((item) => item.variation && item.variation.weight) : false;
  const [datePickerForm] = Form.useForm();
  const [dateRange, setDateRange] = useState([]);
  const [editOrderModalVisible, setEditOrderModalVisible] = useState(false);
  const [orderToEdit, setOrderToEdit] = useState(null);
  const [deliveryType, setDeliveryType] = useState(null);
  const [deliveryDate, setDeliveryDate] = useState(null);
  const [selectedOrderStatus, setSelectedOrderStatus] = useState("all");
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(false);

  moment.locale("nl");

  useEffect(() => {
    if (!!orders) {
      let filteredOrders = orders.filter((order) => order.orderStatus !== ORDER_STATUS_CREATED);
      setFilteredList(filteredOrders);
      setPaymentStatusList(uniqueMergeArrays(orders, "orderStatus"));
      setOrderStatusList([ORDER_STATUS_PAID, ORDER_STATUS_SHIPPED, ORDER_STATUS_COMPLETED, ORDER_STATUS_RETURNED]);
    }
  }, [orders]);

  // We watch for the state to change here, and for the Promise resolve to be available
  useEffect(() => {
    if (isPrinting && promiseResolveRef.current) {
      // Resolves the Promise, letting `react-to-print` know that the DOM updates are completed
      promiseResolveRef.current();
    }
  }, [isPrinting]);

  const handleShowStatus = (value) => {
    setSelectedOrderStatus(value);
    setSearchText("");
    if (value !== "all") {
      const key = "orderStatus";
      const filteredArray = utils.filterArray(orders, key, value);
      setFilteredList(filteredArray);
    } else {
      setFilteredList(orders);
    }
  };

  const showDrawer = (row) => {
    setVisible(true);
    setOrder(row);
  };

  const closeDrawer = () => {
    setVisible(false);
  };

  const formatShippingMethod = (value) => {
    if (value === DELIVERY_OPTIONS.SEND) return t("shipping");
    if (value === DELIVERY_OPTIONS.PICKUP) return t("pickup");
  };

  const getPaymentStatusColor = (status) => {
    if (status === ORDER_STATUS_PAID) return "geekblue";
    else if (status === ORDER_STATUS_SHIPPED) return "lime";
    else if (status === ORDER_STATUS_COMPLETED) return "cyan";
    else if (status === ORDER_STATUS_RETURNED) return "orange";
    else if (status === ORDER_STATUS_CREATED) return "red";
  };

  const handleManualPrint = (functionName) => {
    setIsPrinting(true);
    setTimeout(() => {
      functionName();
    }, 1000);
  };

  const handleOrderListPrint = useReactToPrint({
    contentRef: orderListRef,
    onAfterPrint: () => {
      setIsPrinting(false);
    },
  });

  const handlePickListPrint = useReactToPrint({
    contentRef: pickListRef,
    onAfterPrint: () => {
      setIsPrinting(false);
    },
  });

  const handlePackingSlipPrint = useReactToPrint({
    contentRef: packingSlipRef,
    onAfterPrint: () => {
      setIsPrinting(false);
    },
  });
  //
  const handleSendListPrint = useReactToPrint({
    contentRef: sendListRef,
    onAfterPrint: () => {
      setIsPrinting(false);
    },
  });

  const updateFirebaseOrderStatus = (order, status) => {
    setSelectedRows([]);
    setSelectedRowKeys([]);
    firestore
      .collection(FIRESTORE_ORDERS_TABLE)
      .doc(order.id)
      .update({ orderStatus: status })
      .then(async () => {
        if (status === ORDER_STATUS_PAID) {
          // Send order confirmed mail
          const mailObject = [
            {
              customerEmail: order.customer.email,
              order: {
                ...order,
                documentId: order.id,
                language: order.locale,
                orderDate: dayjs.unix(order.createdAt).format("DD-MM-YYYY"),
              },
            },
          ];
          axios
            .post(`${process.env.REACT_APP_API_URL}/api/email/order/confirmed`, mailObject)
            .then(() => {
              message.success(t("notifications.order_confirmed_mail_sent", { email: order.customer.email }));
            })
            .catch((err) => {
              console.log(err);
              message.error(t("notifications.order_confirmed_mail_sent_failure", { email: order.customer.email }));
            });
        }
        message.success(t("order.status_changed_success"));
      })
      .catch(() => message.error(t("order.status_changed_failure")));
  };

  const handleOptimoRouteExport = async () => {
    const orders = getOrdersReadyToShip();
    if (orders.length > 0) {
      setLoading(true);
      await createDeliveryOrder(orders, firestore).catch(() => {
        setLoading(false);
      });
      setLoading(false);

      const idToken = await getFirebaseIDToken();
      // Send email to Trustpilot for review (only to non gmail addresses, gmail get sent separately)
      const emailList = orders.map((order) => {
        return {
          email: order.customer.email,
          name: order.customer.firstName + " " + order.customer.lastName,
          deliveryDate: dayjs(order.delivery.date, "DD-MM-YYYY").format("YYYY-MM-DD"),
          orderDate: dayjs.unix(order.createdAt).format("YYYY-MM-DD"),
          orderNumber: order.orderId,
          trustpilotReviewInvitationSent: order.trustpilotReviewInvitationSent,
        };
      });
      const emailListWithoutGmail = _.uniqBy(
        emailList.filter((object) => !object.email.includes("gmail") && !object.trustpilotReviewInvitationSent),
        "email",
      );

      if (emailListWithoutGmail.length === 0) return;

      await axios
        .post(process.env.REACT_APP_API_URL + "/api/marketing/trustpilot/review", emailListWithoutGmail, {
          headers: {
            Authorization: `${idToken}`,
          },
        })
        .catch((err) => console.log(err));

      // Set invitationSent to true for all orders
      orders.forEach((order) => {
        firestore.collection(FIRESTORE_ORDERS_TABLE).doc(order.id).update({ trustpilotReviewInvitationSent: true });
      });
    }
  };

  const getOrdersReadyToShip = () => {
    let filteredOrders;
    if (!!dateRange && dateRange.length > 0) {
      let startDate = dateRange[0].format(DATE_FORMAT_YYYY_MM_DD);
      let endDate = dateRange[1].format(DATE_FORMAT_YYYY_MM_DD);
      filteredOrders = _.filter(orders, function (order) {
        return (
          order.orderStatus === ORDER_STATUS_PAID &&
          order.delivery.type === DELIVERY_OPTIONS.SEND &&
          dayjs(order.delivery.date, DATE_FORMAT_DD_MM_YYYY).isBetween(startDate, endDate, undefined, "[]")
        );
      });
    } else {
      filteredOrders = _.filter(selectedRows, function (order) {
        return order.orderStatus === ORDER_STATUS_PAID && order.delivery.type === DELIVERY_OPTIONS.SEND;
      });
    }
    // Remove all gift card only orders
    const filteredByGiftCard = _.filter(filteredOrders, function (order) {
      return !order.containsOnlyGiftCards;
    });
    // Order by date ascending
    return _.orderBy(filteredByGiftCard, ["createdAt"], ["asc"]);
  };

  const getPickupClientEmails = () => {
    const pickupOrders = getFilteredOrders(null, DELIVERY_OPTIONS.PICKUP);
    let emails = "";
    pickupOrders.forEach((order) => (emails += order.customer.email + "; "));
    notification.open({ message: t("pickup_emails"), description: emails, duration: 0 });
  };

  const getPickupAndDeliveryClientEmails = () => {
    const pickupOrders = getFilteredOrders(null, null);
    let emails = "";
    pickupOrders.forEach((order) => (emails += order.customer.email + "; "));
    notification.open({ message: t("pickup_delivery_emails"), description: emails, duration: 0 });
  };

  const getFilteredOrders = (orderStatusFilter, deliveryType) => {
    let filteredOrders;
    // If orderStatusFilter = PAID -> Get only paid orders
    // If not -> Get all orders except those with CREATED status
    if (!!dateRange && dateRange.length > 0) {
      filteredOrders =
        orderStatusFilter === ORDER_STATUS_PAID
          ? filterOrdersDateRange(orders, ORDER_STATUS_PAID, dateRange, true)
          : filterOrdersDateRange(orders, ORDER_STATUS_CREATED, dateRange, false);
    } else {
      filteredOrders =
        orderStatusFilter === ORDER_STATUS_PAID
          ? filterOrdersRows(selectedRows, ORDER_STATUS_PAID)
          : filterOrdersRows(selectedRows, null);
    }

    // Return only shipment orders if deliveryType == send
    if (!!deliveryType && deliveryType === DELIVERY_OPTIONS.SEND) {
      _.remove(filteredOrders, (item) => item.delivery.type === DELIVERY_OPTIONS.PICKUP);
    }

    // Return only shipment orders if deliveryType == send
    if (!!deliveryType && deliveryType === DELIVERY_OPTIONS.PICKUP) {
      _.remove(filteredOrders, (item) => item.delivery.type === DELIVERY_OPTIONS.SEND);
    }

    // Order by date ascending
    return _.orderBy(filteredOrders, ["createdAt"], ["asc"]);
  };

  const filterOrdersDateRange = (orders, statusFilter, dateRange, isEqual) => {
    let startDate = dateRange[0].format(DATE_FORMAT_YYYY_MM_DD);
    let endDate = dateRange[1].format(DATE_FORMAT_YYYY_MM_DD);
    return _.filter(orders, function (order) {
      return (
        (isEqual ? order.orderStatus === statusFilter : order.orderStatus !== statusFilter) &&
        dayjs(order.delivery.date, DATE_FORMAT_DD_MM_YYYY).isBetween(startDate, endDate, undefined, "[]")
      );
    });
  };

  const filterOrdersRows = (orders, statusFilter) => {
    return !!statusFilter
      ? _.filter(orders, function (order) {
          return order.orderStatus === statusFilter;
        })
      : orders;
  };

  const orderStatusMenu = (order) => (
    <Menu>
      {orderStatusList
        .filter((item) => item !== order.orderStatus)
        .map((item, key) => {
          return (
            <Menu.Item key={key}>
              <Popconfirm
                title={
                  <p>
                    {t("order.change_status.intro", { orderId: order.orderId })}{" "}
                    <b>
                      {t("order.change_status.bold", {
                        status: getPaymentStatus(item).toLowerCase(),
                      })}
                    </b>
                    {t("order.change_status.end")}
                  </p>
                }
                onConfirm={() => updateFirebaseOrderStatus(order, item)}
                okText={t("yes")}
                cancelText={t("no")}
              >
                <a onClick={(e) => e.preventDefault()}>{getPaymentStatus(item)}</a>
              </Popconfirm>
            </Menu.Item>
          );
        })}
    </Menu>
  );

  const dropdownMenu = (row) => (
    <Menu>
      <Menu.Item>
        <a onClick={() => openEditModal(row)}>
          <Flex alignItems="center">
            <EditOutlined />
            <span style={{ marginLeft: 4 }}>{t("edit")}</span>
          </Flex>
        </a>
      </Menu.Item>
      <Menu.Item>
        <a onClick={() => showDrawer(row)}>
          <Flex alignItems="center">
            <EyeOutlined />
            <span style={{ marginLeft: 4 }}>{t("details")}</span>
          </Flex>
        </a>
      </Menu.Item>
    </Menu>
  );

  const exportMenu = (
    <Menu>
      <Menu.Item key="1" onClick={handleOptimoRouteExport}>
        <CarOutlined /> <span>{t("optimoroute")}</span>
      </Menu.Item>
      {/*<Menu.Item key="2">*/}
      {/*  <FileExcelOutlined />*/}
      {/*  <ExcelFile*/}
      {/*    filename={COMPANY_CONSTANTS.WEBSITE_NAME + " bestellingen " + moment().format(DATE_FORMAT_DD_MM_YYYY)}*/}
      {/*    element={<span>{t("excel")}</span>}*/}
      {/*  >*/}
      {/*    <ExcelSheet*/}
      {/*      dataSet={*/}
      {/*        selectedRowKeys.length > 0 || (!!dateRange && dateRange.length > 0)*/}
      {/*          ? getExcelExportData(getFilteredOrders(null))*/}
      {/*          : getExcelExportData([])*/}
      {/*      }*/}
      {/*      name={moment().format(DATE_FORMAT_DD_MM_YYYY)}*/}
      {/*    />*/}
      {/*  </ExcelFile>*/}
      {/*</Menu.Item>*/}
    </Menu>
  );

  const printMenu = (
    <Menu>
      <Menu.Item key="1" onClick={() => handleManualPrint(handleOrderListPrint)}>
        {t("order.orders")}
      </Menu.Item>
      <Menu.Item key="2" onClick={() => handleManualPrint(handlePickListPrint)}>
        {t("order.pick_list")}
      </Menu.Item>
      <Menu.Item key="3" onClick={() => handleManualPrint(handlePackingSlipPrint)}>
        {t("order.packing_slips")}
      </Menu.Item>
      <Menu.Item key="4" onClick={() => handleManualPrint(handleSendListPrint)}>
        {t("order.send_labels")}
      </Menu.Item>
      <Menu.Item key="5" onClick={getPickupClientEmails}>
        {t("pickup_emails")}
      </Menu.Item>
      <Menu.Item key="6" onClick={getPickupAndDeliveryClientEmails}>
        {t("pickup_delivery_emails")}
      </Menu.Item>
    </Menu>
  );

  const tableColumns = [
    {
      title: t("order.order_id"),
      dataIndex: "orderId",
    },
    {
      title: t("email"),
      dataIndex: "customer",
      render: (data) => <span>{data.email}</span>,
    },
    {
      title: t("user"),
      dataIndex: "customer",
      render: (data) => (
        <span>
          {data.firstName} {data.lastName}
        </span>
      ),
    },
    {
      title: t("order_date"),
      dataIndex: "createdAt",
      render: (data) => <span>{dayjs.unix(data).format(DATE_FORMAT_DD_MM_YYYY_HH_mm)}</span>,
      sorter: (a, b) => utils.antdTableSorter(a, b, "createdAt"),
      defaultSortOrder: "descend",
    },
    {
      title: t("pickup_delivery_date"),
      dataIndex: "delivery",
      render: (data) => (
        <span>{!!data && !!data.date ? dayjs(data.date, DATE_FORMAT_DD_MM_YYYY).format("ddd D MMMM YYYY") : "-"}</span>
      ),
      sorter: (a, b) =>
        dayjs(a.delivery.date, DATE_FORMAT_DD_MM_YYYY).unix() - dayjs(b.delivery.date, DATE_FORMAT_DD_MM_YYYY).unix(),
    },
    {
      title: t("pickup_type"),
      dataIndex: "delivery",
      render: (data) => (
        <span>{!!data ? (data.type === DELIVERY_OPTIONS.SEND ? t("shipping") : t("pickup_short")) : "-"}</span>
      ),
    },
    {
      title: t("status"),
      dataIndex: "orderStatus",
      render: (_, order) => (
        <>
          <Dropdown overlay={orderStatusMenu(order)} trigger={["click"]}>
            <a onClick={(e) => e.preventDefault()}>
              <Tag color={getPaymentStatusColor(order.orderStatus)}>{getPaymentStatus(order.orderStatus)}</Tag>
            </a>
          </Dropdown>
        </>
      ),
      sorter: (a, b) => utils.antdTableSorter(a, b, "orderStatus"),
    },
    {
      title: t("total"),
      dataIndex: "totalPrice",
      render: (data) => <span className="font-weight-semibold">{CURRENCY + formatPrice(data)}</span>,
      sorter: (a, b) => utils.antdTableSorter(a, b, "totalPrice"),
    },
    {
      title: "",
      render: (_, elm) => (
        <div className="text-right">
          <EllipsisDropdown menu={dropdownMenu(elm)} />
        </div>
      ),
    },
  ];

  const orderDetailsColumns = [
    {
      title: t("name"),
      render: (_, data) => (
        <div className="d-flex">
          <span>
            {data.name} {data.type && "(" + data.type + ")"}
          </span>
        </div>
      ),
    },
    {
      title: t("price"),
      dataIndex: "originalPrice",
      render: (data) => <span>€{formatPrice(data)}</span>,
      align: "center",
    },
    {
      title: t("weight"),
      dataIndex: "variation",
      render: (data) => (data && data.weight ? utils.formatWeight(data.weight) + t("gram_unit") : "-"),
      align: "center",
    },
    {
      title: t("quantity"),
      dataIndex: "quantity",
      render: (data) => <span>{data}</span>,
      align: "center",
    },
    {
      title: t("discount"),
      dataIndex: "discountPercentage",
      render: (data) => <span>{data}%</span>,
      align: "center",
    },
    {
      title: t("sub_total"),
      dataIndex: "paymentPrice",
      render: (_, data) => <span>€{formatPrice(data.paymentPrice * data.quantity)}</span>,
      align: "right",
    },
  ];

  const rowSelection = {
    onChange: (key, rows) => {
      setSelectedRows(rows);
      setSelectedRowKeys(key);
      if (!!dateRange && dateRange.length > 0) {
        setDateRange([]);
        datePickerForm.resetFields();
      }
    },
  };

  const onSearch = (e) => {
    const value = e.currentTarget.value;
    setSearchText(value);
    const data = utils.wildCardSearchOnOrder(orders, value, selectedOrderStatus);
    setFilteredList(data);
    setSelectedRowKeys([]);
  };

  const calculateRevenueAndVat = (orders) => {
    let revenuePerTaxRate = [];
    let orderCounter = 0;
    let productCounter = 0;
    // Map over products in orders. For each product, get the taxRate and add the revenue
    // to the corresponding object in revenuePerTaxRate
    _.map(orders, (order) => {
      _.map(order.products, function (product) {
        productCounter++;
        let index = _.findIndex(revenuePerTaxRate, function (obj) {
          return obj.taxRate === product.taxRate;
        });
        if (index >= 0) revenuePerTaxRate[index].revenue += product.paymentPrice * product.quantity;
        else {
          revenuePerTaxRate.push({
            taxRate: product.taxRate,
            revenue: product.paymentPrice * product.quantity,
          });
        }
      });
    });

    // Go over orders again to put the delivery costs in the appropriate object in
    // revenuePerTaxRate
    _.map(orders, (order) => {
      orderCounter++;
      if (!!order.delivery.cost) {
        let taxRates = _.uniq(_.sortBy(_.map(order.products, "taxRate")));
        let deliveryCostTaxRate = taxRates[taxRates.length - 1];
        let index = _.findIndex(revenuePerTaxRate, function (obj) {
          return obj.taxRate === deliveryCostTaxRate;
        });
        revenuePerTaxRate[index].revenue += order.delivery.cost;
      }
    });

    revenuePerTaxRate = _.sortBy(revenuePerTaxRate, ["taxRate"]);
    let object = { revenuePerTaxRate };
    // Calculate total revenue (incl VAT)
    object.total = _.sumBy(revenuePerTaxRate, "revenue");
    // Calculate total VAT
    object.totalVat = _.sumBy(revenuePerTaxRate, function (obj) {
      return calculateVat(obj.revenue, obj.taxRate);
    });
    object.dateRange = !!dateRange && dateRange.length > 0 ? dateRange : null;
    object.orderAmount = orderCounter;
    object.productAmount = productCounter;
    return object;
  };

  const handleDateRange = (dateRange) => {
    setDateRange(dateRange);
    setSelectedRows([]);
    setSelectedRowKeys([]);
  };

  const openEditModal = (order) => {
    setOrderToEdit(order);
    setDeliveryType(order.delivery.type);
    setDeliveryDate(dayjs(order.delivery.date, "DD-MM-YYYY"));
    editOrderForm.setFieldsValue({
      deliveryType: order.delivery.type,
      deliveryDate: dayjs(order.delivery.date, "DD-MM-YYYY"),
    });
    setEditOrderModalVisible(true);
  };

  const closeEditModal = () => {
    setEditOrderModalVisible(false);
    setOrderToEdit(null);
    setDeliveryType(null);
    setDeliveryDate(null);
  };

  const handleUpdateOrder = () => {
    let firestoreObject = _.clone(orderToEdit.delivery);
    let values = editOrderForm.getFieldsValue();
    firestoreObject.date = dayjs(values.deliveryDate).format("DD-MM-YYYY");
    firestoreObject.dateTimestamp = dayjs(values.deliveryDate).unix();
    firestoreObject.saturdayPickupSelected = dayjs(values.deliveryDate).weekday() === 5;
    firestoreObject.type = values.deliveryType;
    firestore
      .collection(FIRESTORE_ORDERS_TABLE)
      .doc(orderToEdit.id)
      .update({ delivery: firestoreObject })
      .then(() => {
        message.success(t("order_saved"));
        closeEditModal();
      })
      .catch((err) => console.log(err));
  };

  const downloadInvoice = (order) => {
    let invoiceOrder = {
      ...order,
      language: "nl",
      orderDate: formatOrderDate(order.createdAt),
    };
    generateInvoice(invoiceOrder).then((response) => {
      response.blob().then((blob) => downloadFile(blob, formatInvoiceFileName(t("invoice"), order.orderId)));
    });
  };

  return (
    <>
      <Card>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 8,
            gap: 8,
            flexWrap: "wrap",
          }}
        >
          <div style={{ display: "flex", gap: 8, flexWrap: "wrap" }}>
            <Input
              value={searchText}
              placeholder={t("search")}
              prefix={<SearchOutlined />}
              onChange={(e) => onSearch(e)}
              style={{ width: "fit-content" }}
            />
            <Select
              defaultValue="all"
              style={{ minWidth: 180 }}
              onChange={handleShowStatus}
              placeholder={t("status")}
              options={paymentStatusList.concat("all").map((elm) => ({
                label: getPaymentStatus(elm),
                value: elm,
              }))}
            />
          </div>
          <div style={{ display: "flex", gap: 8, flexWrap: "wrap" }}>
            <Tooltip title={t("pickup_delivery_date")}>
              <Form form={datePickerForm}>
                <Form.Item name="dateRange" style={{ marginBottom: 0 }}>
                  <RangePicker
                    onChange={(e) => handleDateRange(e)}
                    locale={locale}
                    placeholder={[t("start_date"), t("end_date")]}
                    format={DATE_FORMAT_DD_MM_YYYY}
                  />
                </Form.Item>
              </Form>
            </Tooltip>
            <Dropdown overlay={printMenu}>
              <Button className="ant-btn-primary">
                <PrinterOutlined /> {t("print")} <DownOutlined />
              </Button>
            </Dropdown>
            <Dropdown overlay={exportMenu}>
              <Button className="ant-btn-primary">
                <FileExcelOutlined /> {t("export")} <DownOutlined />
              </Button>
            </Dropdown>
          </div>
        </div>
        <div className="table-responsive">
          <Table
            columns={tableColumns}
            dataSource={filteredList}
            rowKey="id"
            rowSelection={{
              selectedRowKeys: selectedRowKeys,
              type: "checkbox",
              preserveSelectedRowKeys: false,
              ...rowSelection,
            }}
            scroll={{ x: 1300 }}
            loading={!isLoaded(orders) || loading}
          />
        </div>
        {!!order && (
          <Drawer
            title={order.orderId}
            placement="right"
            onClose={closeDrawer}
            visible={visible}
            width={isMobile ? window.innerWidth : 720}
          >
            <Descriptions
              bordered
              title={
                <div className="flex-row">
                  {t("order.order")}
                  <Tag color={getPaymentStatusColor(order.orderStatus)} className="float-right">
                    {getPaymentStatus(order.orderStatus)}
                  </Tag>
                </div>
              }
              size="small"
              column={1}
            >
              <Descriptions.Item label={t("order_date")}>
                {dayjs.unix(order.createdAt).format(DATE_FORMAT_DD_MM_YYYY_HH_mm)}
              </Descriptions.Item>
              <Descriptions.Item label={t("name")}>
                {order.customer.firstName} {order.customer.lastName}
              </Descriptions.Item>
              <Descriptions.Item label={t("email")}>{order.customer.email}</Descriptions.Item>
              <Descriptions.Item label={t("phone")}>{order.customer.phoneNumber}</Descriptions.Item>
              <Descriptions.Item label={t("delivery_address")}>
                {order.customer.address.delivery.firstName} {order.customer.address.delivery.lastName} <br />
                {order.customer.address.delivery.companyName} {order.customer.address.delivery.companyName && <br />}
                {order.customer.address.delivery.street} {order.customer.address.delivery.houseNumber}{" "}
                {order.customer.address.delivery.addition}
                <br />
                {order.customer.address.delivery.zipCode}, {order.customer.address.delivery.city}
              </Descriptions.Item>
              {!!order.customer.address.invoice && (
                <Descriptions.Item label={t("invoice_address")}>
                  {order.customer.address.invoice.firstName} {order.customer.address.invoice.lastName} <br />
                  {order.customer.address.invoice.companyName} {order.customer.address.invoice.companyName && <br />}
                  {order.customer.address.invoice.street} {order.customer.address.invoice.houseNumber}{" "}
                  {order.customer.address.invoice.addition}
                  <br />
                  {order.customer.address.invoice.zipCode}, {order.customer.address.invoice.city}
                </Descriptions.Item>
              )}
              <Descriptions.Item label={t("shipping_method")}>
                {formatShippingMethod(order.delivery.type)}
              </Descriptions.Item>
              <Descriptions.Item label={t("pickup_delivery_date")}>
                {dayjs(order.delivery.date, DATE_FORMAT_DD_MM_YYYY).format("ddd D MMMM YYYY")}
              </Descriptions.Item>
              <Descriptions.Item label={t("order_notes")}>
                {!!order.orderNotes ? order.orderNotes : "-"}
              </Descriptions.Item>
            </Descriptions>
            <div className="table-responsive pt-5">
              <h4>{t("products")}</h4>
              <Table
                columns={
                  hasWeightProperty
                    ? orderDetailsColumns
                    : orderDetailsColumns.filter((col) => col.dataIndex !== "variation")
                }
                dataSource={order.products}
                pagination={false}
                rowKey="variantId"
                className="w-100"
                bordered={true}
                summary={(pageData) => {
                  let totalAmount = 0;
                  let totalDiscount = 0;

                  pageData.forEach(({ quantity, discount }) => {
                    totalAmount += quantity;
                    totalDiscount += discount;
                  });
                  return (
                    !!order && (
                      <>
                        {order.delivery.type === DELIVERY_OPTIONS.SEND && (
                          <Table.Summary.Row>
                            <Table.Summary.Cell>
                              <Text>{t("shipping")}</Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell />
                            <Table.Summary.Cell />
                            <Table.Summary.Cell />
                            {hasWeightProperty && <Table.Summary.Cell />}
                            <Table.Summary.Cell align="right">
                              <Text>{!!order.delivery.cost ? "€" + formatPrice(order.delivery.cost) : "-"}</Text>
                            </Table.Summary.Cell>
                          </Table.Summary.Row>
                        )}
                        {!!order.discount && (
                          <Table.Summary.Row>
                            <Table.Summary.Cell>
                              <Text>
                                {!!order.discount.percentage ? (
                                  <Trans
                                    i18nKey="cart_discount_percentage"
                                    values={{
                                      discountPercentage: order.discount.percentage,
                                      discountCode: order.discount.code,
                                    }}
                                  />
                                ) : (
                                  <Trans
                                    i18nKey="cart_discount_amount"
                                    values={{
                                      discountAmount: order.discount.amount,
                                      discountCode: order.discount.code,
                                    }}
                                  />
                                )}
                              </Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell />
                            <Table.Summary.Cell />
                            <Table.Summary.Cell />
                            {hasWeightProperty && <Table.Summary.Cell />}
                            <Table.Summary.Cell align="right">
                              <Text>- {CURRENCY + formatPrice(order.discount.totalDiscount)}</Text>
                            </Table.Summary.Cell>
                          </Table.Summary.Row>
                        )}
                        <Table.Summary.Row>
                          <Table.Summary.Cell>
                            <Text strong={true}>{t("total")}</Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell align="center" />
                          {hasWeightProperty && <Table.Summary.Cell align="center" />}
                          <Table.Summary.Cell align="center">
                            <Text strong={true}>{totalAmount}</Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell align="center">
                            <Text strong={true}>€{formatPrice(totalDiscount)}</Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell align="right">
                            <Text strong={true}>€{formatPrice(order.totalPrice)}</Text>
                          </Table.Summary.Cell>
                        </Table.Summary.Row>
                      </>
                    )
                  );
                }}
              />
            </div>
            <Button className={"mt-4"} icon={<DownloadOutlined />} onClick={() => downloadInvoice(order)}>
              {t("download_invoice")}
            </Button>
          </Drawer>
        )}
        {editOrderModalVisible && (
          <Modal
            title={t("edit_order", { orderId: orderToEdit.orderId })}
            visible={editOrderModalVisible}
            onCancel={() => closeEditModal()}
            onOk={handleUpdateOrder}
            okText={t("save")}
            cancelText={t("close")}
          >
            <Form layout="vertical" form={editOrderForm} name="advanced_search" className="ant-advanced-search-form">
              <Form.Item
                name="deliveryType"
                label={t("shipping_method")}
                rules={[
                  {
                    required: true,
                    message: t("form.choose_delivery_type"),
                  },
                ]}
              >
                <Radio.Group onChange={(e) => setDeliveryType(e.target.value)}>
                  <Radio value={"send"} style={{ alignItems: "center" }}>
                    {t("shipping")}
                  </Radio>
                  <Radio value={"pickup"} style={{ alignItems: "center" }}>
                    {t("pickup")}
                  </Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                name="deliveryDate"
                label={t("delivery_date")}
                rules={[
                  {
                    required: true,
                    message: t("form.choose_delivery_date"),
                  },
                ]}
                className={"mb-1"}
              >
                <DatePicker
                  locale={locale}
                  format={DATE_FORMAT_DD_MM_YYYY}
                  placeholder={t("delivery_date")}
                  showToday={false}
                  inputReadOnly={true}
                  style={{ width: "100%" }}
                  onChange={(value) => setDeliveryDate(value)}
                />
              </Form.Item>
              {deliveryDate.diff(dayjs(), "days") < 0 && (
                <DateWarningMessage>{t("order_delivery_date_warning_past")}</DateWarningMessage>
              )}
              {deliveryDate.diff(dayjs(), "days") + 1 > DELIVERY_DATE_WARNING_DAYS && (
                <DateWarningMessage>
                  {t("order_delivery_date_warning", { days: deliveryDate.diff(dayjs(), "days") + 1 })}
                </DateWarningMessage>
              )}
            </Form>
          </Modal>
        )}
        <div style={{ display: "none" }}>
          {isPrinting && (
            <>
              <OrderListTable
                ref={orderListRef}
                data={!!dateRange && dateRange.length > 0 ? getFilteredOrders() : selectedRows}
                orderDetailsColumns={orderDetailsColumns}
                hasWeightProperty={hasWeightProperty}
                formatShippingMethod={formatShippingMethod}
              />
              <PickListTable ref={pickListRef} data={getPickListData(getFilteredOrders(), products, categories)} />
              <PackingSlip ref={packingSlipRef} data={getFilteredOrders()} />
              <SendLabel ref={sendListRef} data={getSendListData(getFilteredOrders())} />
            </>
          )}
        </div>
      </Card>
      {!!orderMaxDaysAgoQuery && (
        <div>
          <p>{t("orders_last_period_explanation")}</p>
          <Button onClick={() => dispatch(setOrdersMaxDaysAgo(null))}>{t("retrieve_all_orders")}</Button>
        </div>
      )}
    </>
  );
};

const DateWarningMessage = styled.p`
  color: #ff7300;
`;

export default Orders;
