import React, { useEffect, useMemo, useState } from "react";
import PageHeaderAlt from "components/layout-components/PageHeaderAlt";
import { Button, Form, message, Tabs } from "antd";
import Flex from "components/shared-components/Flex";
import GeneralField from "./GeneralField";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import _, { intersection } from "lodash";
import { compressImage, getBase64 } from "functions/images";
import { uniqueMergeArrays } from "functions/arrays";
import { useFirebase, useFirestore } from "react-redux-firebase";
import { deleteFilesFromStorage, saveFilesToStorage } from "functions/firebase/storage";
import VariationField from "./VariationField";
import moment from "moment";
import { PRODUCT_IMAGE_COMPRESSION_QUALITY, PRODUCT_IMAGE_MAX_WIDTH } from "constants/ProductConstant";
import ItemsField from "./ItemsField";
import { formatNameToURL } from "../../../../functions/formatters";
import VariantField from "./VariantField";
import { FIRESTORE_PRODUCTS_TABLE } from "../../../../constants/FirestoreConstant";
import { setRelatedProductVariants } from "../../../../functions/firestore/product";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { editProduct } from "../../../../urls";

const { TabPane } = Tabs;

const ADD = "ADD";
const EDIT = "EDIT";
const imagesPath = "products";

const ProductForm = (props) => {
  const { mode = ADD, id } = props;
  const firestore = useFirestore();
  const firebase = useFirebase();
  const [form] = Form.useForm();
  const [imageList, setImageList] = useState([]);
  const [originalImageList, setOriginalImageList] = useState([]);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewTitle, setPreviewTitle] = useState("");
  const [imageOrderChanged, setImageOrderChanged] = useState(false);
  const [newImagesUploaded, setNewImagesUploaded] = useState(false);
  const [showReorderImageNotification, setShowReorderImageNotification] = useState(false);
  const email = useSelector((state) => state.firebaseReducer.auth.email);
  const products = useSelector((state) => state.firestoreReducer.ordered.products);
  const categories = useSelector((state) => state.firestoreReducer.ordered.categories);
  const types = uniqueMergeArrays(products, "type");
  const allergens = uniqueMergeArrays(products, "allergens");
  const tags = uniqueMergeArrays(products, "tag");
  const productId = id || null;
  const clickedProduct = useMemo(() => _.find(products, ["id", productId]) || {}, [products, productId]);
  const [prevProduct, setPrevProduct] = useState(null);
  const [nextProduct, setNextProduct] = useState(null);

  const { t } = useTranslation();

  useEffect(() => {
    if (mode === EDIT) {
      getStorageFiles().then((result) => {
        setImageList(result);
        setOriginalImageList(result);
      });
      form.setFieldsValue({
        url: !!clickedProduct.url ? clickedProduct.url : formatNameToURL(clickedProduct.name),
        name: clickedProduct.name,
        shortDescription: !!clickedProduct.shortDescription ? clickedProduct.shortDescription : null,
        description: clickedProduct.description,
        categories: clickedProduct.categories,
        type: !!clickedProduct.type ? clickedProduct.type : undefined,
        allergens: !!clickedProduct.allergens ? clickedProduct.allergens : undefined,
        price: clickedProduct.price,
        taxRate: clickedProduct.taxRate,
        discount: clickedProduct.discount,
        stock: clickedProduct.stock,
        min_amount: !!clickedProduct.min_amount ? clickedProduct.min_amount : 1,
        variations: clickedProduct.variations,
        productItems: clickedProduct.productItems,
        additionalInfo: clickedProduct.additionalInfo,
        productVariants: clickedProduct.productVariants,
        variantName: clickedProduct.variantName,
        hideVariant: clickedProduct.hideVariant || false,
        onlyRegional: clickedProduct.onlyRegional || false,
        tag: clickedProduct.tag,
      });
      const productIndex = _.findIndex(products, ["id", productId]);
      setPrevProduct(products[(productIndex + products.length - 1) % products.length].id);
      setNextProduct(products[(productIndex + 1) % products.length].id);
    }
  }, [form, mode, id, props, clickedProduct]);

  /**
   * Retrieve files from firebase storage with their links
   * @returns Array of images
   */
  function getStorageFiles() {
    let promises = [];
    if (!!clickedProduct.image) {
      clickedProduct.image.forEach((imageUrl, index) => {
        let imageRef = firebase.storage().refFromURL(imageUrl);
        promises.push(
          imageRef
            .getMetadata()
            .then((info) => {
              return {
                uid: info.name,
                url: imageUrl,
                name: info.name,
                path: info.fullPath,
              };
            })
            .catch(() => {
              // Image does not exist in storage anymore
              return {};
            }),
        );
      });
    }
    return Promise.all(promises);
  }

  const handleUploadChange = ({ fileList }) => {
    let uploadedFile = fileList[fileList.length - 1];
    if (!!uploadedFile && uploadedFile.status === "done") {
      setNewImagesUploaded(true);
      // Compress image before setting imageList state
      compressImage(uploadedFile.originFileObj, PRODUCT_IMAGE_COMPRESSION_QUALITY, PRODUCT_IMAGE_MAX_WIDTH).then(
        (compressedImage) => {
          let compressedImageClone = new Blob([compressedImage], {
            type: compressedImage.type,
          });
          compressedImageClone.name = compressedImage.lastModified + "-" + compressedImage.name;
          // Clone object, it may have some read-only properties
          fileList[fileList.length - 1] = {
            ...fileList[fileList.length - 1],
            originFileObj: compressedImageClone,
            name: compressedImage.lastModified + "-" + compressedImage.name,
            size: compressedImage.size,
          };
          setImageList(fileList);
        },
      );
    } else setImageList(fileList);
  };

  const handleUploadOrderChange = (fileList) => {
    setImageList(fileList);
    setImageOrderChanged(true);
    if (newImagesUploaded) setShowReorderImageNotification(true);
  };

  const handlePreviewCancel = () => {
    setPreviewVisible(false);
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf("/") + 1));
  };

  const setProductLoggingProperties = (product, mode) => {
    if (mode === ADD) return { ...product, createdAt: moment().unix(), createdBy: email };
    else if (mode === EDIT) return { ...product, updatedAt: moment().unix(), updatedBy: email };
  };

  const formatProductUrl = (productName) => {
    if (mode === ADD) {
      let formattedName = formatNameToURL(productName);
      form.setFieldsValue({ url: formattedName });
    }
  };

  const onFinish = () => {
    setSubmitLoading(true);
    const newProduct = form.getFieldsValue();
    let parentPrice = form.getFieldValue("price");
    // If no variations are set, set to null instead of undefined
    // Same for type
    let variations = form.getFieldValue("variations") || null;
    let productItems = form.getFieldValue("productItems") || null;
    let productVariants = form.getFieldValue("productVariants") || null;
    let type = form.getFieldValue("type") || null;
    let allergens = form.getFieldValue("allergens") || null;
    let additionalInfo = form.getFieldValue("additionalInfo") || null;
    let shortDescription = form.getFieldValue("shortDescription") || null;
    let tag = form.getFieldValue("tag")?.[0] || null;
    // When all variations are deleted, we end up with empty array. If so, set variations to null
    // Same for type & items
    if (variations && variations.length === 0) variations = null;
    if (productItems && productItems.length === 0) productItems = null;
    if (productVariants && productVariants.length === 0) productVariants = null;
    if (type && type.length === 0) type = null;
    if (allergens && allergens.length === 0) allergens = null;
    newProduct.variations = variations;
    newProduct.productItems = productItems;
    newProduct.productVariants = productVariants;
    newProduct.type = type;
    newProduct.allergens = allergens;
    newProduct.additionalInfo = additionalInfo;
    newProduct.shortDescription = shortDescription;
    newProduct.tag = tag;
    let variationContainsParentPrice = _.find(variations, {
      price: parentPrice,
    });
    let validVariationPrice = !!variationContainsParentPrice || !variations;
    let productWithLogging = setProductLoggingProperties(newProduct, mode);

    let productCategories = form.getFieldValue("categories");
    let categoriesInvalid = false;
    // Check whether main and subcategory have both been chosen, which we do not want
    productCategories.forEach((category) => {
      let mainCategory = _.find(categories, ["categoryId", category]);
      if (!!mainCategory && mainCategory.subcategoryIds.length > 0) {
        if (intersection(mainCategory.subcategoryIds, productCategories).length > 0) {
          message.error(t("notifications.category_subcategory_overlap"));
          setSubmitLoading(false);
          categoriesInvalid = true;
        }
      }
    });
    if (!categoriesInvalid && imageList.length >= 1 && validVariationPrice) {
      form
        .validateFields()
        .then(() => {
          if (!!productVariants) setRelatedProductVariants(firestore, productId, productVariants);

          /** ADD PRODUCT */
          if (mode === ADD) {
            let imageUrlArray = [];
            let fileArray = [];
            // Push images to array, we will add those to product image array.
            imageList.forEach((item) => {
              fileArray.push(item.originFileObj);
            });
            // Update the product with the new form data
            firestore
              .collection(FIRESTORE_PRODUCTS_TABLE)
              .add(productWithLogging)
              .then((docRef) => {
                saveFilesToStorage(firebase, imagesPath, fileArray, imageUrlArray, docRef.id).catch((err) =>
                  message.error(err.message),
                );
                form.resetFields();
                setImageList([]);
                message.success(t("product_added"), 4);
              })
              .catch((err) => message.error(err.message));
          }
          /** EDIT PRODUCT */
          if (mode === EDIT) {
            let newFiles = [];
            let imageUrlArray = [];

            // Push existing files to imageUrlArray, we do not need to re-upload those.
            // Push new files to newFiles array, we will add those to product image array.
            imageList.forEach((item) => {
              if (!!item.url) imageUrlArray.push(item.url);
              else newFiles.push(item.originFileObj);
            });

            // Go over the original image list and check which ones are removed. Put those in corresponding arrays
            let toDeleteFromStorage = [];
            let toDeleteFromFirestore = [];
            _.forEach(originalImageList, function (value) {
              if (value.status === "removed") {
                toDeleteFromStorage.push(value.path);
                toDeleteFromFirestore.push(value.url);
              }
            });

            let imageUrls = [];
            // Save new files to storage if added
            if (newFiles.length > 0) {
              saveFilesToStorage(firebase, imagesPath, newFiles, imageUrlArray, productId)
                .then(() => {
                  updateProduct(productWithLogging);
                })
                .catch((err) => message.error(err.message));
            } else if (imageOrderChanged) {
              imageList.forEach((image) => {
                let foundImage = _.find(imageList, (item) => item.url === image.url);
                if (!!foundImage) {
                  imageUrls.push(image.url);
                }
              });
              productWithLogging.image = imageUrls;
              updateProduct(productWithLogging);
            } else updateProduct(productWithLogging);

            // Delete files from storage if removed
            if (toDeleteFromStorage.length > 0) {
              deleteFilesFromStorage(firebase, firestore, toDeleteFromStorage, productId, toDeleteFromFirestore);
            }

            function updateProduct(updatedProduct) {
              // Update the product with the new form data
              firestore
                .collection(FIRESTORE_PRODUCTS_TABLE)
                .doc(productId)
                .update(updatedProduct)
                .then(() => {
                  message.success(t("product_saved"), 4);
                  setOriginalImageList(imageList);
                  setShowReorderImageNotification(false);
                  setNewImagesUploaded(false);
                })
                .catch((err) => message.error(err.message));
            }
          }
          setSubmitLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setSubmitLoading(false);
          message.error(t("enter_required_data"));
        });
    } else {
      setSubmitLoading(false);
      if (imageList.length < 1) message.error(t("notifications.minimum_product_images"));
      if (!validVariationPrice) message.error(t("notifications.minimum_parent_price_variation"));
    }
  };

  return (
    <>
      <Form
        layout="vertical"
        form={form}
        name="advanced_search"
        className="ant-advanced-search-form"
        initialValues={{
          discount: 0,
          stock: 0,
          min_amount: 1,
          hideVariant: false,
          onlyRegional: false,
        }}
      >
        <PageHeaderAlt className="border-bottom" overlap>
          <div>
            <Flex className="py-2" mobileFlex={false} justifyContent="between" alignItems="center">
              <div>
                <h2>{mode === "ADD" ? t("add_new_product") : t("edit_product")} </h2>
                {mode === EDIT && !!prevProduct && !!nextProduct && (
                  <PrevNextProduct>
                    <Link to={editProduct + prevProduct}>
                      <LeftOutlined />
                    </Link>
                    <p>{clickedProduct.name}</p>
                    <Link to={editProduct + nextProduct}>
                      <RightOutlined />
                    </Link>
                  </PrevNextProduct>
                )}
              </div>
              <div>
                <Button type="primary" onClick={() => onFinish()} htmlType="submit" loading={submitLoading}>
                  {mode === "ADD" ? t("add") : t("save")}
                </Button>
              </div>
            </Flex>
          </div>
        </PageHeaderAlt>
        <div>
          <Tabs defaultActiveKey="1" style={{ marginTop: 30 }}>
            <TabPane tab={t("general")} key="1">
              <GeneralField
                form={form}
                formatProductUrl={formatProductUrl}
                products={products}
                fileList={imageList}
                handleUploadChange={handleUploadChange}
                handleUploadOrderChange={handleUploadOrderChange}
                categories={categories}
                types={types ? types : null}
                allergens={allergens ? allergens : null}
                tags={tags ? tags : null}
                handlePreview={handlePreview}
                previewVisible={previewVisible}
                previewImage={previewImage}
                previewTitle={previewTitle}
                handlePreviewCancel={handlePreviewCancel}
                productId={productId}
                showReorderNotification={showReorderImageNotification}
              />
            </TabPane>
            <TabPane tab={t("variations.variation")} key="2">
              <VariationField form={form} variations={!!clickedProduct && clickedProduct.variations} />
            </TabPane>
            <TabPane tab={t("items")} key="3">
              <ItemsField products={products} />
            </TabPane>
            <TabPane tab={t("product_variants")} key="4">
              <VariantField products={products} productId={productId} />
            </TabPane>
          </Tabs>
        </div>
      </Form>
    </>
  );
};

const PrevNextProduct = styled.div`
  display: flex;

  p {
    font-size: 15px;
    width: 170px;
    margin-left: 4px;
    margin-right: 4px;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-bottom: 0;
  }

  a {
    font-size: 15px;
    line-height: 27px;
  }
`;

export default ProductForm;
