import React from "react";
import { Card, Checkbox, Col, Form, Input, InputNumber, message, Modal, Row, Select, TreeSelect, Upload } from "antd";
import i18n from "translations/i18n";
import { InfoCircleOutlined, PlusOutlined } from "@ant-design/icons";
import {
  ADDITIONAL_INFO_TEXT_ROWS,
  DESCRIPTION_TEXT_ROWS,
  SHORT_DESCRIPTION_TEXT_MAX_CHAR,
  SHORT_DESCRIPTION_TEXT_ROWS,
} from "../../../../constants/InputConstant";
import ImgCrop from "antd-img-crop";
import { PRODUCT_IMAGE_ASPECT_RATIO } from "constants/ProductConstant";
import { validProductUrl } from "../../../../functions/validator";
import { cloneDeep } from "lodash";
// import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
// import UploadList from "antd/es/upload/UploadList";
import styled from "styled-components";

const { Option } = Select;

const imageUploadProps = {
  name: "file",
  multiple: true,
  listType: "picture-card",
};

/**
 * Image uploader expects to do a request, so we mock one
 */
const dummyRequest = ({ file, onSuccess }) => {
  onSuccess("ok");
};

/**
 * When file is uploaded, check for file type and image size
 * @param {*} file The uploaded file
 * @returns Boolean that tells Upload component whether to add image or not
 */
const beforeUpload = (file) => {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error(i18n.t("notifications.only_png_jpg"));
    return Upload.LIST_IGNORE;
  }
  const isLt2M = file.size / 1024 / 1024 < 4;
  if (!isLt2M) {
    message.error(i18n.t("notifications.image_max_size"));
    return Upload.LIST_IGNORE;
  }
  return isJpgOrPng && isLt2M;
};

const uploadButton = (
  <div>
    <PlusOutlined />
    <div style={{ marginTop: 8 }}>{i18n.t("upload")}</div>
  </div>
);

const GeneralField = (props) => {
  const rules = {
    name: [
      {
        required: true,
        message: i18n.t("form.enter_product_name"),
      },
    ],
    description: [
      {
        required: true,
        message: i18n.t("form.enter_description"),
      },
    ],
    price: [
      {
        required: true,
        message: i18n.t("form.enter_price"),
      },
    ],
    taxRate: [
      {
        required: true,
        message: i18n.t("form.enter_taxrate"),
      },
    ],
    categories: [
      {
        required: true,
        message: i18n.t("form.enter_category"),
      },
    ],
    discount: [
      {
        required: true,
        message: i18n.t("form.enter_discount"),
      },
    ],
    stock: [
      {
        required: true,
        message: i18n.t("form.enter_stock"),
      },
    ],
    url: [
      {
        required: true,
        message: i18n.t("form.enter_url"),
      },
      () => ({
        validator(rule, value) {
          return validProductUrl(value, props.productId, props.products)
            ? Promise.resolve()
            : Promise.reject(i18n.t("form.url_not_unique"));
        },
      }),
    ],
    min_amount: [
      {
        required: true,
        message: i18n.t("form.enter_min_amount"),
      },
    ],
  };

  const onDragEnd = ({ source, destination }) => {
    const reorder = (list, startIndex, endIndex) => {
      const [removed] = list.splice(startIndex, 1);
      list.splice(endIndex, 0, removed);

      return list;
    };

    const newFileList = reorder(cloneDeep(props.fileList), source.index, destination.index);
    props.handleUploadOrderChange(newFileList);
  };

  return (
    <Row gutter={16}>
      <Col xs={24} sm={24} md={17}>
        <Card title={i18n.t("product_info")}>
          <Row gutter={16}>
            <Col xs={19}>
              <Form.Item name="name" label={i18n.t("product_name")} rules={rules.name}>
                <Input placeholder={i18n.t("name")} onChange={(e) => props.formatProductUrl(e.target.value)} />
              </Form.Item>
            </Col>
            <Col xs={5}>
              <Form.Item name="onlyRegional" label={i18n.t("only_regional")} valuePropName={"checked"}>
                <Checkbox />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={18}>
              <Form.Item name="shortDescription" label={i18n.t("short_description")}>
                <Input.TextArea
                  rows={SHORT_DESCRIPTION_TEXT_ROWS}
                  showCount
                  maxLength={SHORT_DESCRIPTION_TEXT_MAX_CHAR}
                  placeholder={i18n.t("short_description")}
                />
              </Form.Item>
            </Col>
            <Col xs={6}>
              <Form.Item name="tag" label={i18n.t("tag")}>
                <Select style={{ width: "100%" }} placeholder={i18n.t("tag")} mode={"tags"} maxCount={1}>
                  {props.tags?.map(
                    (tag) =>
                      !!tag && (
                        <Option key={tag} value={tag}>
                          {tag}
                        </Option>
                      ),
                  )}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            name="description"
            label={i18n.t("description")}
            rules={rules.description}
            tooltip={{ title: i18n.t("form.description_tooltip"), icon: <InfoCircleOutlined /> }}
          >
            <Input.TextArea rows={DESCRIPTION_TEXT_ROWS} showCount placeholder={i18n.t("description")} />
          </Form.Item>
          <Form.Item
            name="additionalInfo"
            label={i18n.t("additional_info")}
            tooltip={{ title: i18n.t("form.description_tooltip"), icon: <InfoCircleOutlined /> }}
          >
            <Input.TextArea rows={ADDITIONAL_INFO_TEXT_ROWS} showCount placeholder={i18n.t("additional_info")} />
          </Form.Item>
          <Form.Item name="stock" label={i18n.t("stock")} rules={rules.stock}>
            <InputNumber
              className="w-100"
              max={200000}
              formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            />
          </Form.Item>
          <Form.Item name="min_amount" label={i18n.t("min_amount")} rules={rules.min_amount}>
            <InputNumber
              className="w-100"
              min={1}
              formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            />
          </Form.Item>
        </Card>
        <Card title={i18n.t("seo")}>
          <Row gutter={16}>
            <Col xs={24} sm={24} md={24}>
              <Form.Item name={"url"} label={i18n.t("product_url")} rules={rules.url}>
                <Input addonBefore={process.env.REACT_APP_PRODUCT_URL} />
              </Form.Item>
            </Col>
          </Row>
        </Card>
        <Card title={i18n.t("pricing")}>
          <Row gutter={16}>
            <Col xs={24} sm={24} md={12}>
              <Form.Item name="price" label={i18n.t("price")} rules={rules.price}>
                <InputNumber
                  className="w-100"
                  formatter={(value) => `€ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <Form.Item name="taxRate" label={i18n.t("tax_rate")} rules={rules.taxRate}>
                <InputNumber
                  className="w-100"
                  min={0}
                  max={100}
                  formatter={(value) => `${value}%`}
                  parser={(value) => value.replace("%", "")}
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item name="discount" label={i18n.t("discount")} rules={rules.discount}>
            <InputNumber
              className="w-100"
              min={0}
              max={99}
              formatter={(value) => `${value}%`}
              parser={(value) => value.replace("%", "")}
            />
          </Form.Item>
        </Card>
      </Col>
      <Col xs={24} sm={24} md={7}>
        <Card title={i18n.t("media")}>
          <ImgCrop
            modalWidth={1000}
            minZoom={0}
            modalTitle={i18n.t("crop_image")}
            modalOk={i18n.t("add")}
            modalCancel={i18n.t("close")}
            aspect={PRODUCT_IMAGE_ASPECT_RATIO}
            cropperProps={{
              restrictPosition: false,
            }}
          >
            <Upload
              {...imageUploadProps}
              fileList={props.fileList}
              beforeUpload={beforeUpload}
              onChange={(e) => props.handleUploadChange(e)}
              onPreview={(e) => props.handlePreview(e)}
              customRequest={dummyRequest}
              maxCount={4}
              multiple={false}
            >
              {props.fileList.length >= 4 ? null : uploadButton}
            </Upload>
          </ImgCrop>
          {/*{props.fileList && (*/}
          {/*  <DragDropContext onDragEnd={onDragEnd}>*/}
          {/*    <Droppable droppableId="droppable">*/}
          {/*      {(provided) => (*/}
          {/*        <div ref={provided.innerRef} {...provided.droppableProps}>*/}
          {/*          {props.fileList.map((item, index) => (*/}
          {/*            <Draggable key={item.uid} draggableId={item.uid} index={index}>*/}
          {/*              {(provided) => (*/}
          {/*                <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>*/}
          {/*                  <UploadList*/}
          {/*                    showDownloadIcon={false}*/}
          {/*                    listType={props.listType}*/}
          {/*                    onPreview={props.handlePreview}*/}
          {/*                    items={[item]}*/}
          {/*                  />*/}
          {/*                </div>*/}
          {/*              )}*/}
          {/*            </Draggable>*/}
          {/*          ))}*/}
          {/*          {provided.placeholder}*/}
          {/*        </div>*/}
          {/*      )}*/}
          {/*    </Droppable>*/}
          {/*  </DragDropContext>*/}
          {/*)}*/}
          {props.showReorderNotification && (
            <ReorderNotification>{i18n.t("reorderImagesNotification")}</ReorderNotification>
          )}
          <Modal
            visible={props.previewVisible}
            title={props.previewTitle}
            footer={null}
            onCancel={props.handlePreviewCancel}
          >
            <img alt="example" style={{ width: "100%" }} src={props.previewImage} />
          </Modal>
        </Card>
        <Card title={i18n.t("category")}>
          <Form.Item name="categories" label={i18n.t("categories")} rules={rules.categories}>
            <TreeSelect
              showSearch
              style={{ width: "100%" }}
              placeholder={i18n.t("form.choose_specific_category")}
              allowClear
              multiple
              treeDefaultExpandAll
              treeData={props.categories?.map((category) => {
                return {
                  key: category.categoryId,
                  title: category.name,
                  value: category.categoryId,
                  children: category.subcategories?.map((subcategory) => {
                    return {
                      title: subcategory.name,
                      value: subcategory.subcategoryId,
                      key: subcategory.subcategoryId,
                    };
                  }),
                };
              })}
            />
          </Form.Item>
        </Card>
        <Card title={i18n.t("types")}>
          <Form.Item name="type" label={i18n.t("type")}>
            <Select
              mode="tags"
              style={{ width: "100%" }}
              placeholder={i18n.t("form.enter_type")}
              notFoundContent={<span>{i18n.t("table_no_types")}</span>}
            >
              {props.types.map(
                (type) =>
                  !!type && (
                    <Option key={type} value={type}>
                      {type}
                    </Option>
                  ),
              )}
            </Select>
          </Form.Item>
        </Card>
        <Card title={i18n.t("allergens")}>
          <Form.Item name="allergens" label={i18n.t("allergens")}>
            <Select
              mode="tags"
              style={{ width: "100%" }}
              placeholder={i18n.t("form.enter_allergen")}
              notFoundContent={<span>{i18n.t("table_no_allergens")}</span>}
            >
              {props.allergens.map(
                (allergen) =>
                  !!allergen && (
                    <Option key={allergen} value={allergen}>
                      {allergen}
                    </Option>
                  ),
              )}
            </Select>
          </Form.Item>
        </Card>
      </Col>
    </Row>
  );
};

const ReorderNotification = styled.p`
  color: #ff2020;
  font-weight: 500;
  margin-bottom: 0;
`;

export default GeneralField;
