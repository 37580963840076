import React from "react";
import { Card, Table } from "antd";
import Flex from "components/shared-components/Flex";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useFirestoreConnect } from "react-redux-firebase";
import { FIRESTORE_VACANCY_APPLICATIONS_TABLE } from "../../../constants/FirestoreConstant";
import dayjs from "moment";
import { DATE_FORMAT_DD_MM_YYYY } from "../../../constants/DateConstant";
import utils from "../../../utils";

const JobApplications = () => {
  useFirestoreConnect([{ collection: FIRESTORE_VACANCY_APPLICATIONS_TABLE }]);
  const vacancyApplications = useSelector((state) => state.firestoreReducer?.ordered?.vacancyApplications);
  const { t } = useTranslation();

  const tableColumns = [
    {
      title: t("submitted_on"),
      dataIndex: "createdAt",
      render: (data) => <span>{dayjs.unix(data).format(DATE_FORMAT_DD_MM_YYYY)}</span>,
      sorter: (a, b) => utils.antdTableSorter(a, b, "createdAt"),
      defaultSortOrder: "descend",
    },
    {
      title: t("name"),
      dataIndex: "name",
      render: (data) => (data ? <span>{data}</span> : <span> - </span>),
    },
    {
      title: t("email"),
      dataIndex: "email",
      render: (data) => <span>{data}</span>,
    },
    {
      title: t("phone"),
      dataIndex: "phone",
      render: (data) => (data ? <span>{data}</span> : <span> - </span>),
    },
    {
      title: t("vacancy"),
      dataIndex: "vacancy",
      render: (data) => (data ? <span>{data}</span> : <span> - </span>),
    },
  ];

  return (
    <Card>
      <Flex alignItems="center" justifyContent="between" mobileFlex={false}></Flex>
      <div className="table-responsive">
        <Table columns={tableColumns} dataSource={vacancyApplications} rowKey="createdAt" scroll={{ x: 1300 }} />
      </div>
    </Card>
  );
};

export default JobApplications;
